import React, { Component } from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import Link from 'gatsby-link'
import Panel from "../components/panel.js"
import { graphql } from "gatsby"
import styles from '../components/panel.module.css'
import {Helmet} from 'react-helmet'

const SerialTextTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div className={styles.serialDiv}>
      <Header />
      <Helmet>
          <title>{post.frontmatter.title}</title>
      </Helmet>
      <div className={styles.panel}>
        <div className={styles.centeredText}>
          <Link to={post.frontmatter.prev}>&lt;&lt;&lt; Previous </Link>|
          <Link to={post.frontmatter.parent}> Story Home </Link> |
          <Link to={post.frontmatter.next}> Next &gt;&gt;&gt;</Link>
        </div>
        <h2 className={styles.h}>{post.frontmatter.title}</h2>
        {post.frontmatter.date}
        <Panel post={{__html: post.html}} />
        <div className={styles.centeredText}>
            <Link to={post.frontmatter.prev}>&lt;&lt;&lt; Previous </Link>|
            <Link to={post.frontmatter.parent}> Story Home </Link> |
            <Link to={post.frontmatter.next}> Next &gt;&gt;&gt;</Link>
        </div>  
      </div>
      <Footer />
    </div>
  )
}


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        parent
        date(formatString: "YYYY-MM-DD")
        prev
        next
      }
    }
  }
`
export default SerialTextTemplate